import React, { useState } from 'react';
import './galary.css';
import Vstaging0 from '../../Assets/images/Virtual Staging/01_I.jpg';
import Vstaging1 from '../../Assets/images/Virtual Staging/01_II.jpg';
import Vstaging2 from '../../Assets/images/Virtual Staging/02_I.jpg';
import Vstaging3 from '../../Assets/images/Virtual Staging/02_II.jpg';
import Vstaging4 from '../../Assets/images/Virtual Staging/03_I.jpg';
import Vstaging5 from '../../Assets/images/Virtual Staging/03_II.jpg';
import Vstaging6 from '../../Assets/images/Virtual Staging/04_I.jpg';
import Vstaging7 from '../../Assets/images/Virtual Staging/04_II.jpg';
import Vstaging8 from '../../Assets/images/Virtual Staging/05_I.jpg';
import Vstaging9 from '../../Assets/images/Virtual Staging/05_II.jpg';
import Vstaging10 from '../../Assets/images/Virtual Staging/06_I.jpg';
import Vstaging11 from '../../Assets/images/Virtual Staging/06_II.jpg';
import Vstaging12 from '../../Assets/images/Virtual Staging/07_I.jpg';
import Vstaging13 from '../../Assets/images/Virtual Staging/07_II.jpg';
import Vstaging14 from '../../Assets/images/Virtual Staging/08_I.jpg';
import Vstaging15 from '../../Assets/images/Virtual Staging/08_II.jpg';
import Vstaging16 from '../../Assets/images/Virtual Staging/09_I.jpg';
import Vstaging17 from '../../Assets/images/Virtual Staging/09_II.jpg';
import Vstaging18 from '../../Assets/images/Virtual Staging/10_I.jpg';
import Vstaging19 from '../../Assets/images/Virtual Staging/10_II.jpg';
import Vstaging20 from '../../Assets/images/Virtual Staging/11_I.jpg';
import Vstaging21 from '../../Assets/images/Virtual Staging/11_II.jpg';
import Vstaging22 from '../../Assets/images/Virtual Staging/12_I.jpg';
import Vstaging23 from '../../Assets/images/Virtual Staging/12_II.jpg';
import Vstaging24 from '../../Assets/images/Virtual Staging/13_I.jpg';
import Vstaging25 from '../../Assets/images/Virtual Staging/13_II.jpg';
import Vstaging26 from '../../Assets/images/Virtual Staging/14_I.jpg';
import Vstaging27 from '../../Assets/images/Virtual Staging/14_II.jpg';
import Vstaging28 from '../../Assets/images/Virtual Staging/15_I.jpg';
import Vstaging29 from '../../Assets/images/Virtual Staging/15_II.jpg';
import Vstaging30 from '../../Assets/images/Virtual Staging/16_I.jpg';
import Vstaging31 from '../../Assets/images/Virtual Staging/16_II.jpg';
import Vstaging32 from '../../Assets/images/Virtual Staging/17_I.jpg';
import Vstaging33 from '../../Assets/images/Virtual Staging/17_II.jpg';
import Vstaging34 from '../../Assets/images/Virtual Staging/18_I.jpg';
import Vstaging35 from '../../Assets/images/Virtual Staging/18_II.jpg';
import Vstaging36 from '../../Assets/images/Virtual Staging/22KinglakeCres-7-1480x986.jpg';
import Vstaging37 from '../../Assets/images/Virtual Staging/22KinglakeCres-7-final-1480x986.jpg';
import Vstaging38 from '../../Assets/images/Virtual Staging/22KinglakeCres-43-1480x986.jpg';
import Vstaging39 from '../../Assets/images/Virtual Staging/22KinglakeCres-43_Final-1480x986.jpg';
import Vstaging40 from '../../Assets/images/Virtual Staging/DSC_2048.jpg';

import ImageModal from '../ImageModel/ImageModel';

const Vstaging = () => {
  const images = [
    Vstaging0, Vstaging1, Vstaging2, Vstaging3, Vstaging4, Vstaging5, Vstaging6, Vstaging7,
    Vstaging8, Vstaging9, Vstaging10, Vstaging11, Vstaging12, Vstaging13, Vstaging14, Vstaging15,
    Vstaging16, Vstaging17, Vstaging18, Vstaging19, Vstaging20, Vstaging21, Vstaging22, Vstaging23,
    Vstaging24, Vstaging25, Vstaging26, Vstaging27, Vstaging28, Vstaging29, Vstaging30, Vstaging31,
    Vstaging32, Vstaging33, Vstaging34, Vstaging35, Vstaging36, Vstaging37, Vstaging38, Vstaging39, Vstaging40
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div style={{ background: 'linear-gradient(#1cefff,#1cefff)', minHeight: '100vh' }}>
    <div className="gallery-section" >
      <h2>Virtual Staging Gallery</h2>
      <div className="gallery">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Flash ${index + 1}`} onClick={() => openModal(index)} />
          </div>
        ))}
      </div>
      <ImageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        image={images[currentImageIndex]}
        onNext={nextImage}
        onPrev={prevImage}
      />
    </div>
    </div>
  );
};

export default Vstaging;

import React, { useState } from 'react';
import './galary.css';
import hdr0 from '../../Assets/images/HDR/1.jpg';
import hdr1 from '../../Assets/images/HDR/2.jpg';
import hdr2 from '../../Assets/images/HDR/3.jpg';
import hdr3 from '../../Assets/images/HDR/4.jpg';
import hdr4 from '../../Assets/images/HDR/5.jpg';
import hdr5 from '../../Assets/images/HDR/7.jpg';
import hdr6 from '../../Assets/images/HDR/8.jpg';
import hdr7 from '../../Assets/images/HDR/9.jpg';
import hdr8 from '../../Assets/images/HDR/10.jpg';
import hdr9 from '../../Assets/images/HDR/11.jpg';
import hdr10 from '../../Assets/images/HDR/13.jpg';
import hdr11 from '../../Assets/images/HDR/14.jpg';
import hdr12 from '../../Assets/images/HDR/15.jpg';
import hdr13 from '../../Assets/images/HDR/16.jpg';
import hdr14 from '../../Assets/images/HDR/17.jpg';
import hdr15 from '../../Assets/images/HDR/18.jpg';
import hdr16 from '../../Assets/images/HDR/18AuburnDv-201005-83-1480x986.jpg';
import hdr17 from '../../Assets/images/HDR/18LewishamPlace-200930-9-1480x986.jpg';
import hdr18 from '../../Assets/images/HDR/18LewishamPlace-200930-37-1480x986.jpg';
import hdr19 from '../../Assets/images/HDR/19.jpg';
import hdr20 from '../../Assets/images/HDR/20.jpg';
import hdr21 from '../../Assets/images/HDR/22.jpg';
import hdr22 from '../../Assets/images/HDR/22KinglakeCres-3-final-1480x986.jpg';
import hdr23 from '../../Assets/images/HDR/22KinglakeCres-54-1480x986.jpg';
import hdr24 from '../../Assets/images/HDR/23.jpg';
import hdr25 from '../../Assets/images/HDR/35DomainAve-201002-103-1480x986.jpg';
import hdr26 from '../../Assets/images/HDR/68AWinifredSt-36-1480x986.jpg';
import hdr27 from '../../Assets/images/HDR/68AWinifredSt-40-1480x986.jpg';
import hdr28 from '../../Assets/images/HDR/68AWinifredSt-45-1480x986.jpg';
import hdr29 from '../../Assets/images/HDR/420LygonStreet_Level6-200929-17-1480x986.jpg';
import hdr30 from '../../Assets/images/HDR/420LygonStreet_Level6-200929-30-1480x986.jpg';
import hdr31 from '../../Assets/images/HDR/474623264.jpg';
import hdr32 from '../../Assets/images/HDR/474630428.jpg';
import hdr33 from '../../Assets/images/HDR/474645181.jpg';
import hdr34 from '../../Assets/images/HDR/474686662.jpg';
import hdr35 from '../../Assets/images/HDR/474689307.jpg';
import hdr36 from '../../Assets/images/HDR/474697362.jpg';
import hdr37 from '../../Assets/images/HDR/474702899.jpg';
import hdr38 from '../../Assets/images/HDR/474712054.jpg';
import hdr39 from '../../Assets/images/HDR/474717890.jpg';
import hdr40 from '../../Assets/images/HDR/474726225.jpg';
import hdr41 from '../../Assets/images/HDR/474734632.jpg';
import hdr42 from '../../Assets/images/HDR/474751362.jpg';
import hdr43 from '../../Assets/images/HDR/474758798.jpg';
import hdr44 from '../../Assets/images/HDR/474760278.jpg';
import hdr45 from '../../Assets/images/HDR/474799715.jpg';
import hdr46 from '../../Assets/images/HDR/474824988.jpg';
import hdr47 from '../../Assets/images/HDR/474842102.jpg';
import hdr48 from '../../Assets/images/HDR/474862712.jpg';
import hdr49 from '../../Assets/images/HDR/474874857.jpg';
import hdr50 from '../../Assets/images/HDR/474913743.jpg';
import hdr51 from '../../Assets/images/HDR/474930946.jpg';
import hdr52 from '../../Assets/images/HDR/474974499.jpg';
import hdr53 from '../../Assets/images/HDR/474976673.jpg';
import hdr54 from '../../Assets/images/HDR/475098401.jpg';
import hdr55 from '../../Assets/images/HDR/APZ_7353.jpg';
import hdr56 from '../../Assets/images/HDR/APZ_7356.jpg';
import hdr57 from '../../Assets/images/HDR/APZ_7359.jpg';
import hdr58 from '../../Assets/images/HDR/APZ_7362.jpg';
import hdr59 from '../../Assets/images/HDR/APZ_7367.jpg';
import hdr60 from '../../Assets/images/HDR/APZ_7371.jpg';
import hdr61 from '../../Assets/images/HDR/APZ_7381.jpg';
import hdr62 from '../../Assets/images/HDR/DJI_20240.jpg';
import hdr63 from '../../Assets/images/HDR/DJI_202408.jpg';
import hdr64 from '../../Assets/images/HDR/DJI_20240812100026_0011_D_2_D_3_D_4_D_5_D.jpg';
import hdr65 from '../../Assets/images/HDR/DJI_20240812100058_0016_D_17_D_18_D_19_D_20_D.jpg';
import hdr66 from '../../Assets/images/HDR/DJI_20240812100126_0021_D_6_0022_D_7_0023_D_7_0024_D_7_0025_D.jpg';
import hdr67 from '../../Assets/images/HDR/DJI_20240812100156_0026_D_27_D_28_D_29_D_30_D.jpg';
import hdr68 from '../../Assets/images/HDR/DJI_20240813141635_0135_D_6_D_7_D_8_D_9_D.jpg';
import hdr69 from '../../Assets/images/HDR/DJI_20240813141925_0155_D_6_D_7_D_8_D_9_D.jpg';
import hdr70 from '../../Assets/images/HDR/DJI_20240813141953_0160_D_1_D_2_D_3_D_4_D.jpg';
import hdr71 from '../../Assets/images/HDR/DJI_20240813142035_0165_D_6_0166_D_6_0167_D_6_0168_D_6_0169_D.jpg';
import hdr72 from '../../Assets/images/HDR/DJI_20240813142214_0175_D_6_D_7_D_8_D_9_D.jpg';
import hdr73 from '../../Assets/images/HDR/DJI_20240813142308_0180_D_1_D_2_D_3_D_4_D.jpg';
import hdr74 from '../../Assets/images/HDR/gallery-1-21-1200x800.jpg';
import hdr75 from '../../Assets/images/HDR/gallery-4-4-1200x800.jpg';
import hdr76 from '../../Assets/images/HDR/gallery-4-1200x800.jpg';
import hdr77 from '../../Assets/images/HDR/IMG_4416.jpg';
import hdr78 from '../../Assets/images/HDR/IMG_4421.jpg';
import hdr79 from '../../Assets/images/HDR/IMG_4431.jpg';
import hdr80 from '../../Assets/images/HDR/IMG_4521.jpg';
import hdr81 from '../../Assets/images/HDR/IMG_4526.jpg';
import hdr82 from '../../Assets/images/HDR/LightHousePointClose_20200714_001_2_3-Edit-1480x986.jpg';
import hdr83 from '../../Assets/images/HDR/LightHousePointClose_20200714_040_1_2-Edit-1480x986.jpg';
import hdr84 from '../../Assets/images/HDR/Output027.jpg';
import hdr85 from '../../Assets/images/HDR/Output039.jpg';
import hdr86 from '../../Assets/images/HDR/Output043.jpg';
import hdr87 from '../../Assets/images/HDR/Output044.jpg';
import hdr88 from '../../Assets/images/HDR/Output045.jpg';
import hdr89 from '../../Assets/images/HDR/Output046.jpg';
import hdr90 from '../../Assets/images/HDR/Output051.jpg';
import hdr91 from '../../Assets/images/HDR/Output052.jpg';
import hdr92 from '../../Assets/images/HDR/Output054.jpg';
import hdr93 from '../../Assets/images/HDR/Output069.jpg';
import hdr94 from '../../Assets/images/HDR/Output070.jpg';
import hdr95 from '../../Assets/images/HDR/PANA7752.jpg';
import hdr96 from '../../Assets/images/HDR/PANA7757.jpg';
import hdr97 from '../../Assets/images/HDR/PANA7762.jpg';
import hdr98 from '../../Assets/images/HDR/PANA7772.jpg';
import hdr99 from '../../Assets/images/HDR/PANA7777.jpg';
import hdr100 from '../../Assets/images/HDR/PANA7782.jpg';
import hdr101 from '../../Assets/images/HDR/PANA7787.jpg';
import hdr102 from '../../Assets/images/HDR/PANA7792.jpg';
import hdr103 from '../../Assets/images/HDR/PANA7797.jpg';
import hdr104 from '../../Assets/images/HDR/PANA7802.jpg';
import hdr105 from '../../Assets/images/HDR/SZ31411_2_3-Edit-copy-1480x986.jpg';
import hdr106 from '../../Assets/images/HDR/SZ37609_Final-1480x986.jpg';
import hdr107 from '../../Assets/images/HDR/SZ38284-1480x986.jpg';

import ImageModal from '../ImageModel/ImageModel';

const Hdr = () => {
  const images = [
    hdr0, hdr1, hdr2, hdr3, hdr4, hdr5, hdr6, hdr7, hdr8, hdr9,
    hdr10, hdr11, hdr12, hdr13, hdr14, hdr15, hdr16, hdr17, hdr18, hdr19,
    hdr20, hdr21, hdr22, hdr23, hdr24, hdr25, hdr26, hdr27, hdr28, hdr29,
    hdr30, hdr31, hdr32, hdr33, hdr34, hdr35, hdr36, hdr37, hdr38, hdr39,
    hdr40, hdr41, hdr42, hdr43, hdr44, hdr45, hdr46, hdr47, hdr48, hdr49,
    hdr50, hdr51, hdr52, hdr53, hdr54, hdr55, hdr56, hdr57, hdr58, hdr59,
    hdr60, hdr61, hdr62, hdr63, hdr64, hdr65, hdr66, hdr67, hdr68, hdr69,
    hdr70, hdr71, hdr72, hdr73, hdr74, hdr75, hdr76, hdr77, hdr78, hdr79,
    hdr80, hdr81, hdr82, hdr83, hdr84, hdr85, hdr86, hdr87, hdr88, hdr89,
    hdr90, hdr91, hdr92, hdr93, hdr94, hdr95, hdr96, hdr97, hdr98, hdr99,
    hdr100, hdr101, hdr102, hdr103, hdr104, hdr105, hdr106, hdr107
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div style={{ background: 'linear-gradient(#1cefff,#1cefff)', minHeight: '100vh' }}>
    <div className="gallery-section" >
      <h2>HDR Editing Gallery</h2>
      <div className="gallery">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Flash ${index + 1}`} onClick={() => openModal(index)} />
          </div>
        ))}
      </div>
      <ImageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        image={images[currentImageIndex]}
        onNext={nextImage}
        onPrev={prevImage}
      />
    </div>
    </div>
  );
};

export default Hdr;

import React, { useState } from 'react';
import './galary.css';
import item0 from '../../Assets/images/Removal/A1.jpg';
import item1 from '../../Assets/images/Removal/A2.jpg';
import item2 from '../../Assets/images/Removal/A3.jpg';
import item3 from '../../Assets/images/Removal/A4.jpg';
import item4 from '../../Assets/images/Removal/A5.jpg';
import item5 from '../../Assets/images/Removal/A6.jpg';
import item6 from '../../Assets/images/Removal/A7.jpg';
import item7 from '../../Assets/images/Removal/A8.jpg';
import item8 from '../../Assets/images/Removal/A9.jpg';
import item9 from '../../Assets/images/Removal/A10.jpg';
import item10 from '../../Assets/images/Removal/A11.jpg';
import item11 from '../../Assets/images/Removal/A12.jpg';
import item12 from '../../Assets/images/Removal/DSC_4537.jpg';
import item13 from '../../Assets/images/Removal/DSC_4537 1.jpg';
import item14 from '../../Assets/images/Removal/DSC_4542.jpg';
import item15 from '../../Assets/images/Removal/DSC_4542 1.jpg';
import item16 from '../../Assets/images/Removal/DSC_4547.jpg';
import item17 from '../../Assets/images/Removal/DSC_4547 1.jpg';
import item18 from '../../Assets/images/Removal/DSC_4552.jpg';
import item19 from '../../Assets/images/Removal/DSC_4552 1.jpg';
import item20 from '../../Assets/images/Removal/DSC_9952.jpg';
import item21 from '../../Assets/images/Removal/DSC_9952 1.jpg';
import item22 from '../../Assets/images/Removal/IMG_0090 1.jpg';
import item23 from '../../Assets/images/Removal/IMG_0091.jpg';
import item24 from '../../Assets/images/Removal/IMG_0145.jpg';
import item25 from '../../Assets/images/Removal/IMG_0145 1.jpg';
import ImageModal from '../ImageModel/ImageModel';

const ItemRemoval = () => {
  const images = [
    item0, item1, item2, item3, item4, item5, item6, item7, item8, item9,
    item10, item11, item12, item13, item14, item15, item16, item17, item18, item19,
    item20, item21, item22, item23, item24, item25
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div style={{ background: 'linear-gradient(#1cefff,#1cefff)', minHeight: '100vh' }}>
    <div className="gallery-section" >
      <h2> Item Removal Gallery</h2>
      <div className="gallery">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Flash ${index + 1}`} onClick={() => openModal(index)} />
          </div>
        ))}
      </div>
      <ImageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        image={images[currentImageIndex]}
        onNext={nextImage}
        onPrev={prevImage}
      />
    </div>
    </div>
  );
};

export default ItemRemoval;

import React, { useState } from 'react';
import './galary.css';
import twilight0 from '../../Assets/images/Twilight/18AuburnDv-201005-148-1480x986.jpg';
import twilight1 from '../../Assets/images/Twilight/22KinglakeCres-78-1480x986.jpg';
import twilight2 from '../../Assets/images/Twilight/22KinglakeCres-78-1480x986 (1).jpg';
import twilight3 from '../../Assets/images/Twilight/22KinglakeCres-98-Edit-1480x986.jpg';
import twilight4 from '../../Assets/images/Twilight/22KinglakeCres-98-Edit-1480x986 (1).jpg';
import twilight5 from '../../Assets/images/Twilight/22KinglakeCres-102-Edit-1480x986.jpg';
import twilight6 from '../../Assets/images/Twilight/35DomainAve-201002-189-1480x986.jpg';
import twilight7 from '../../Assets/images/Twilight/35DomainAve-201002-189-1480x986 (1).jpg';
import twilight8 from '../../Assets/images/Twilight/A (1).jpg';
import twilight9 from '../../Assets/images/Twilight/A (3).jpg';
import twilight10 from '../../Assets/images/Twilight/A (4).jpg';
import twilight11 from '../../Assets/images/Twilight/A (5).jpg';
import twilight12 from '../../Assets/images/Twilight/C (9).jpg';
import twilight13 from '../../Assets/images/Twilight/C (13).jpg';
import twilight14 from '../../Assets/images/Twilight/C (14).jpg';
import twilight15 from '../../Assets/images/Twilight/C (15).jpg';
import twilight16 from '../../Assets/images/Twilight/C (16).jpg';
import twilight17 from '../../Assets/images/Twilight/C (17).jpg';
import twilight18 from '../../Assets/images/Twilight/C (18).jpg';
import twilight19 from '../../Assets/images/Twilight/gallery-3-4-1200x800.jpg';
import twilight20 from '../../Assets/images/Twilight/gallery-3-5-1200x800.jpg';
import twilight21 from '../../Assets/images/Twilight/gallery-3-12-1200x800.jpg';
import twilight22 from '../../Assets/images/Twilight/sz39530-2-Edit-1480x992.jpg';
import twilight23 from '../../Assets/images/Twilight/SZ30557-1480x986.jpg';
import twilight24 from '../../Assets/images/Twilight/SZ37997-Edit-1480x1074.jpg';
import twilight25 from '../../Assets/images/Twilight/SZ38018-1480x986.jpg';
import twilight26 from '../../Assets/images/Twilight/SZ39392-Edit-1480x986.jpg';
import twilight27 from '../../Assets/images/Twilight/SZ39392-Edit-1480x986 (1).jpg';

import ImageModal from '../ImageModel/ImageModel';

const Twlight = () => {
  const images = [
    twilight0, twilight1, twilight2, twilight3, twilight4, twilight5, twilight6, twilight7,
    twilight8, twilight9, twilight10, twilight11, twilight12, twilight13, twilight14, twilight15,
    twilight16, twilight17, twilight18, twilight19, twilight20, twilight21, twilight22, twilight23,
    twilight24, twilight25, twilight26, twilight27
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div style={{ background: 'linear-gradient(#1cefff,#1cefff)', minHeight: '100vh' }}>
    <div className="gallery-section" >
      <h2>Twlight Gallery</h2>
      <div className="gallery">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Flash ${index + 1}`} onClick={() => openModal(index)} />
          </div>
        ))}
      </div>
      <ImageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        image={images[currentImageIndex]}
        onNext={nextImage}
        onPrev={prevImage}
      />
    </div>
    </div>
  );
};

export default Twlight;

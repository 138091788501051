import React, { useState } from 'react';
import './galary.css';
import flam1 from '../../Assets/images/Flamient/2D8A4174_75_76_77_78_79_80.jpg';
import flam2 from '../../Assets/images/Flamient/2D8A4181_2_3_4_5_6_7.jpg';
import flam3 from '../../Assets/images/Flamient/2D8A4209_10_11_12_13_14_15.jpg';
import flam4 from '../../Assets/images/Flamient/2D8A4244_45_46_47_48_49_50.jpg';
import flam5 from '../../Assets/images/Flamient/2D8A4314_15_16_17_18_19_20.jpg';
import flam6 from '../../Assets/images/Flamient/2D8A4321_2_3_4_5_6_7.jpg';
import flam7 from '../../Assets/images/Flamient/2D8A4328_29_30_31_32_33_34.jpg';
import flam8 from '../../Assets/images/Flamient/2D8A4398_399_400_401_402_403_404.jpg';
import flam9 from '../../Assets/images/Flamient/2D8A4412_3_4_5_6_7_8.jpg';
import flam10 from '../../Assets/images/Flamient/2D8A6907_8_9.jpg';
import flam11 from '../../Assets/images/Flamient/2D8A6915_6_7.jpg';
import flam12 from '../../Assets/images/Flamient/2D8A6958_59_60.jpg';
import flam13 from '../../Assets/images/Flamient/2D8A7017_8_9.jpg';
import flam14 from '../../Assets/images/Flamient/2D8A7038_39_40.jpg';
import flam15 from '../../Assets/images/Flamient/_Q7A4547.jpg';
import flam16 from '../../Assets/images/Flamient/_Q7A4550.jpg';
import flam17 from '../../Assets/images/Flamient/_Q7A4554.jpg';
import flam18 from '../../Assets/images/Flamient/_Q7A4557.jpg';
import flam19 from '../../Assets/images/Flamient/_Q7A4560.jpg';
import flam20 from '../../Assets/images/Flamient/_Q7A4563.jpg';
import flam21 from '../../Assets/images/Flamient/_Q7A4565.jpg';
import flam22 from '../../Assets/images/Flamient/_Q7A4570.jpg';
import flam23 from '../../Assets/images/Flamient/_Q7A4573.jpg';
import flam24 from '../../Assets/images/Flamient/_Q7A4575.jpg';
import flam25 from '../../Assets/images/Flamient/_Q7A4577.jpg';
import flam26 from '../../Assets/images/Flamient/_Q7A4585.jpg';
import flam27 from '../../Assets/images/Flamient/_Q7A4588.jpg';
import flam28 from '../../Assets/images/Flamient/_Q7A4590.jpg';
import flam29 from '../../Assets/images/Flamient/_Q7A4592.jpg';
import flam30 from '../../Assets/images/Flamient/_Q7A4611.jpg';
import ImageModal from '../ImageModel/ImageModel';

const FlashGallery = () => {
  const images = [
    flam1, flam2, flam3, flam4, flam5, flam6, flam7, flam8, flam9, flam10, 
    flam11, flam12, flam13, flam14, flam15, flam16, flam17, flam18, flam19, flam20, 
    flam21, flam22, flam23, flam24, flam25, flam26, flam27, flam28, flam29, flam30
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div style={{ background: 'linear-gradient(#1cefff,#1cefff)', minHeight: '100vh' }}>
    <div className="gallery-section" >
      <h2>Flamient Gallery</h2>
      <div className="gallery">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Flash ${index + 1}`} onClick={() => openModal(index)} />
          </div>
        ))}
      </div>
      <ImageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        image={images[currentImageIndex]}
        onNext={nextImage}
        onPrev={prevImage}
      />
    </div>
    </div>
  );
};

export default FlashGallery;

import React, { useState } from 'react';
import './galary.css';
import dtd0 from '../../Assets/images/Day to dusk/APZ_1012.jpg';
import dtd1 from '../../Assets/images/Day to dusk/APZ_1012-.jpg';
import dtd2 from '../../Assets/images/Day to dusk/APZ_1255.jpg';
import dtd3 from '../../Assets/images/Day to dusk/APZ_1255-.jpg';
import dtd4 from '../../Assets/images/Day to dusk/APZ_1469.jpg';
import dtd5 from '../../Assets/images/Day to dusk/APZ_1469-.jpg';
import dtd6 from '../../Assets/images/Day to dusk/APZ_1574.jpg';
import dtd7 from '../../Assets/images/Day to dusk/APZ_1574-.jpg';
import dtd8 from '../../Assets/images/Day to dusk/APZ_1740.jpg';
import dtd9 from '../../Assets/images/Day to dusk/APZ_1740-.jpg';
import dtd10 from '../../Assets/images/Day to dusk/APZ_1750.jpg';
import dtd11 from '../../Assets/images/Day to dusk/APZ_1750-.jpg';
import dtd12 from '../../Assets/images/Day to dusk/APZ_2672.jpg';
import dtd13 from '../../Assets/images/Day to dusk/APZ_2672-.jpg';
import dtd14 from '../../Assets/images/Day to dusk/Twilight (1).jpg';
import dtd15 from '../../Assets/images/Day to dusk/Twilight (1)-.jpg';
import dtd16 from '../../Assets/images/Day to dusk/Twilight (2).jpg';
import dtd17 from '../../Assets/images/Day to dusk/Twilight (2)-.jpg';
import dtd18 from '../../Assets/images/Day to dusk/Twilight (3).jpg';
import dtd19 from '../../Assets/images/Day to dusk/Twilight (3)-.jpg';
import dtd20 from '../../Assets/images/Day to dusk/Twilight (4).jpg';
import dtd21 from '../../Assets/images/Day to dusk/Twilight (4)-.jpg';
import dtd22 from '../../Assets/images/Day to dusk/Twilight (5).jpg';
import dtd23 from '../../Assets/images/Day to dusk/Twilight (5)-.jpg';
import dtd24 from '../../Assets/images/Day to dusk/Twilight (6).jpg';
import dtd25 from '../../Assets/images/Day to dusk/Twilight (6)-.jpg';
import dtd26 from '../../Assets/images/Day to dusk/Twilight (8).jpg';
import dtd27 from '../../Assets/images/Day to dusk/Twilight (8)-.jpg';
import dtd28 from '../../Assets/images/Day to dusk/Twilight (9).jpg';
import dtd29 from '../../Assets/images/Day to dusk/Twilight (9)-.jpg';
import dtd30 from '../../Assets/images/Day to dusk/Twilight (10).jpg';
import dtd31 from '../../Assets/images/Day to dusk/Twilight (10)-.jpg';
import dtd32 from '../../Assets/images/Day to dusk/Twilight (11).jpg';
import dtd33 from '../../Assets/images/Day to dusk/Twilight (11)-.jpg';
import dtd34 from '../../Assets/images/Day to dusk/Twilight (12).jpg';
import dtd35 from '../../Assets/images/Day to dusk/Twilight (12)-.jpg';

import ImageModal from '../ImageModel/ImageModel';

const Daytodusk = () => {
  const images = [
    dtd0, dtd1, dtd2, dtd3, dtd4, dtd5,
    dtd6, dtd7, dtd8, dtd9, dtd10, dtd11,
    dtd12, dtd13, dtd14, dtd15, dtd16, dtd17,
    dtd18, dtd19, dtd20, dtd21, dtd22, dtd23,
    dtd24, dtd25, dtd26, dtd27, dtd28, dtd29,
    dtd30, dtd31, dtd32, dtd33, dtd34, dtd35
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div style={{ background: 'linear-gradient(#1cefff,#1cefff)', minHeight: '100vh' }}>
    <div className="gallery-section" >
      <h2>Day To Dusk</h2>
      <div className="gallery">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Flash ${index + 1}`} onClick={() => openModal(index)} />
          </div>
        ))}
      </div>
      <ImageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        image={images[currentImageIndex]}
        onNext={nextImage}
        onPrev={prevImage}
      />
    </div>
    </div>
  );
};

export default Daytodusk;

import React from 'react';
import SwiperCore, { Pagination, EffectCoverflow, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './Service.css';
import daytodush from '../../Assets/daytodush.jpg';
import flash from '../../Assets/flash.jpg';
import hdr from '../../Assets/hdr.jpg';
import twilight from '../../Assets/twilight.jpg';
import itemremoval from '../../Assets/itemremoval.jpg';
import vstaging from '../../Assets/vstaging.jpg';
import single from '../../Assets/single.jpg';

SwiperCore.use([Pagination, EffectCoverflow, Autoplay]);

const Posts = [

  {
    id: 2,
    postImage: flash,
    title: "Flash Editing",
     href: "/gallery/flash"
  },
  {
    id: 3,
    postImage: vstaging,
    title: "Virtual Staging",
    href:"/gallery/vstaging"
  },
  {
    id: 4,
    postImage: daytodush,
    title: "Day to Dusk Conversion",
    href:"/gallery/daytodusk"     
  },
  {
    id: 5,
    postImage: twilight,
    title: "Twilight Photography",
    href:"/gallery/twlight"
  },
  {
    id: 6,
    postImage: hdr,
    title: "HDR Ambient Blending",
    href:"/gallery/hdr" 
  },
  {
    id: 8,
    postImage: itemremoval,
    title: "Item Removal",
    href: "/gallery/itemRemoval"
  },
];

const Service = () => {
  return (
    <section className='service-section container section' id="services">
       <div className='service-sec-container'>
          <h1 className='service-sec-title'>Services We Offer</h1>
          <p>Explore the range of services we provide to enhance your images.</p>
          <div className="service-main-container">
            <Swiper 
              className='service-swiper'
              modules={[Pagination, EffectCoverflow, Autoplay]}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 3,
                  slideShadows: true
              }}
              loop={true}
              pagination={{clickable: true}}
              autoplay={{
                  delay: 5000,
                  disableOnInteraction: false
              }}
              breakpoints={{
                  640: {
                      slidesPerView: 2
                  },
                  768: {
                      slidesPerView: 1
                  },
                  1024: {
                      slidesPerView: 2
                  },
                  1560: {
                      slidesPerView: 3
                  },
              }}
            >
              {Posts.map((data) => (
                <SwiperSlide 
                  key={data.id} 
                  style={{ backgroundImage: `url(${data.postImage})` }} 
                  className="service-swiper-slide"
                >
                  <div className="service-slide-content">
                    <h2>{data.title}</h2>
                    <a 
                      href={data.href} 
                      target={data.href ? "_self" : "_blank"} 
                      rel="noopener noreferrer" 
                      className='service-slider-btn'
                    >
                      Explore
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
    </section>
  );
}

export default Service;
